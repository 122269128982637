export const roleOptions = [
  { label: '个人用户', value: '个人用户' },
  { label: '开发者', value: '开发者' },
  { label: '企业用户', value: '企业用户' },
  { label: '其他', value: '其他' },
];

export const sceneOptions = [
  {
    label: 'PDF/WORD文档',
    value: 'PDF/WORD文档',
    children: [
      { label: '年报/研报/ESG报告/招股书', value: '年报/研报/ESG报告/招股书' },
      { label: '国家标准/专利', value: '国家标准/专利' },
      { label: '论文/扫描书/题库', value: '论文/扫描书/题库' },
      { label: '招投标文件/合同', value: '招投标文件/合同' },
      { label: '图纸', value: '图纸' },
      { label: '其他', value: '其他' },
    ],
  },
  {
    label: '票据',
    value: '票据',
    children: [
      { label: '增值税发票', value: '增值税发票' },
      { label: '餐饮小票', value: '餐饮小票' },
      { label: '医疗票据', value: '医疗票据' },
      { label: ' 交通票据', value: ' 交通票据' },
      { label: '收据单', value: '收据单' },
      { label: '其他', value: '其他' },
    ],
  },
  {
    label: '卡证',
    value: '卡证',
    children: [
      { label: '银行卡', value: '银行卡' },
      { label: '名片', value: '名片' },
      { label: '个人证照', value: '个人证照' },
      { label: '企业证照', value: '企业证照' },
      { label: '其他', value: '其他' },
    ],
  },
  {
    label: '拍照图片',
    value: '拍照图片',
  },
  {
    label: '其他',
    value: '其他',
  },
];

export const amountOptions = [
  { label: '0~1K次/页', value: '0~1K次/页' },
  { label: '1K~5K次/页', value: '1K~5K次/页' },
  { label: '5K~1W次/页', value: '5K~1W次/页' },
  { label: '1W~2W次/页', value: '1W~2W次/页' },
  { label: '2W~5W次/页', value: '2W~5W次/页' },
  { label: '大于5W次/页', value: '大于5W次/页' },
];

export const userPortraitsActivityDict = {
  login1000: {
    value: 1,
    label: '登录流程赠送1000活动',
  },
};

export const currentPortraitsActivity = () => userPortraitsActivityDict.login1000;
