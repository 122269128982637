import { useEffect } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { track } from '@/utils/track';
import { getQrCodeByUrlPath } from '@/constants/qrcode';
import GuideArrow from './assets/guide_arrow.png';
import styles from './WechatBanner.module.scss';

const WechatBanner = () => {
  const router = useRouter();
  const currentPath = router.asPath?.split('?')[0];
  const qrCode = getQrCodeByUrlPath(currentPath);

  useEffect(() => {
    track({
      keyword: '邀请入群【横版banner】（曝光）',
      url: window.location.href,
    });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.leftContent}>
          <div className={styles.title}>
            扫码添加「 TextIn 助手」加入技术交流群，获取免费额度、大额折扣、产品技术咨询
          </div>
          <div className={styles.desc}> 已经有6000+名技术同行加入，共话智能文档新生态！</div>
        </div>
        <div className={styles.guide}>
          <Image src={GuideArrow} width={194} height={194} alt="" />
        </div>
        <div className={styles.qrCode}>
          <Image src={qrCode} width={100} height={100} alt="" />
          <div className={styles.qrCodeText}>扫码领取免费额度</div>
        </div>
      </div>
    </div>
  );
};

export default WechatBanner;
