import type { ButtonProps } from 'antd';
import { Button } from 'antd';
import classNames from 'classnames';
import type { FC } from 'react';
import styles from './index.module.scss';
const SubmitButton: FC<ButtonProps & { active: boolean }> = ({ children, active, ...props }) => {
  return (
    <Button
      type="primary"
      className={classNames(styles['submit-btn-style'], {
        [styles['submit-btn-deActive']]: !active,
      })}
      {...props}
    >
      {children}
    </Button>
  );
};
export default SubmitButton;
