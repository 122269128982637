// 全站二维码配置
const textinHome = 'textin_home'; // 主站
const textinExtract = 'textin_extract'; // 智能抽取
const textinText = 'textin_text'; // 文字识别
const padfToMarkdownApi = 'pdf_to_markdown_api'; //文档解析api文档
const padfToMarkdown = 'pdf_to_markdown'; // 文档解析
const textinImage = 'textin_image'; // 图像处理
const textinDocflow = 'textin_docflow'; // 票据自动化
const textinBill = 'textin_bill'; // 票据识别
const textinCard = 'textin_card'; // 卡证识别
const textinConversion = 'textin_conversion'; // 格式转化
const recognizeTable = 'recognize_table'; // 表格识别
// const textinTools = 'textin_tools'; // Textin tools
const textinIntfinq = 'textin_intfinq'; // IntFinQ 知识管理及写作助手
const others = 'others';
export const qrcodeConfig: Record<string, any> = {
  [textinHome]: {
    codeImage: '/images/qrcode/textin_home.png',
    paths: ['/'],
  },
  [textinExtract]: {
    codeImage: '/images/qrcode/textin_extract.png',
    paths: ['/product/textin_extract', '/document/open_kie_vlm_engine'],
  },
  [textinText]: {
    codeImage: '/images/qrcode/textin_text.png',
    paths: [
      '/product/textin_text',
      '/market/detail/recognize-document-3d1-multipage',
      '/market/chager/recognize-document-3d1-multipage',
      '/experience/recognize-document-3d1-multipage',
      '/document/recognize-document-3d1-multipage',
    ],
  },
  [padfToMarkdownApi]: {
    codeImage: '/images/qrcode/pdf_to_markdown_api.png',
    paths: ['/document/pdf_to_markdown'],
  },
  [padfToMarkdown]: {
    codeImage: '/images/qrcode/pdf_to_markdown.png',
    paths: [
      '/market/detail/pdf_to_markdown',
      '/market/chager/pdf_to_markdown',
      '/experience/pdf_to_markdown',
    ],
  },
  [textinImage]: {
    codeImage: '/images/qrcode/textin_image.png',
    paths: [
      '/product/textin_image',
      // 图像水印去除
      '/market/detail/watermark-remove',
      '/market/chager/watermark-remove',
      'experience/watermark-remove',
      '/document/watermark-remove',
      // 切边增强
      '/market/detail/crop_enhance_image',
      '/market/chager/crop_enhance_image-remove',
      '/experience/crop_enhance_image',
      '/document/crop_enhance_image',
      // 自动擦除
      '/market/detail/text_auto_removal',
      '/market/chager/text_auto_removal-remove',
      '/experience/text_auto_removal',
      '/document/text_auto_removal',
      // 质量检测
      '/market/detail/image_quality_inspect',
      '/market/chager/image_quality_inspect-remove',
      '/experience/image_quality_inspect',
      '/document/image_quality_inspect',
    ],
  },
  [textinDocflow]: {
    codeImage: '/images/qrcode/textin_docflow.png',
    paths: ['/product/textin_docflow', '/document/docflow_openapi_guide'],
  },
  [textinBill]: {
    codeImage: '/images/qrcode/textin_bill.png',
    paths: [
      '/product/textin_bill',
      '/market/detail/bill_recognize_v2',
      '/market/chager/bill_recognize_v2',
      '/experience/bill_recognize_v2',
      '/document/bill_recognize_v2',
      '/market/detail/verify_vat',
      '/market/chager/verify_vat',
      '/experience/verify_vat',
      '/document/verify_vat',
      '/market/detail/general_receipt_classify',
      '/market/chager/general_receipt_classify',
      '/experience/general_receipt_classify',
      '/document/general_receipt_classify',
      '/market/detail/vehicle_sales_invoice',
      '/market/chager/vehicle_sales_invoice',
      '/experience/vehicle_sales_invoice',
      '/document/vehicle_sales_invoice',
      '/market/detail/electr_acceptance_bill',
      '/market/chager/electr_acceptance_bill',
      '/experience/electr_acceptance_bill',
      '/document/electr_acceptance_bill',
      '/market/detail/used_vehicle_sales_invoice',
      '/market/chager/used_vehicle_sales_invoice',
      '/experience/used_vehicle_sales_invoice',
      '/document/used_vehicle_sales_invoice',
      '/market/detail/taxi_invoice',
      '/market/chager/taxi_invoice',
      '/experience/taxi_invoice',
      '/document/taxi_invoice',
      '/market/detail/air_transport_itinerary',
      '/market/chager/air_transport_itinerary',
      '/experience/air_transport_itinerary',
      '/document/air_transport_itinerary',
      '/market/detail/quota_invoice',
      '/market/chager/quota_invoice',
      '/experience/quota_invoice',
      '/document/quota_invoice',
      '/market/detail/receipt',
      '/market/chager/receipt',
      '/experience/receipt',
      '/document/receipt',
      '/market/detail/customs_declaration',
      '/market/chager/customs_declaration',
      '/experience/customs_declaration',
      '/document/customs_declaration',
      '/market/detail/bank_receipts',
      '/market/chager/bank_receipts',
      '/experience/bank_receipts',
      '/document/bank_receipts',
      '/market/detail/commercial_insurance_policy',
      '/market/chager/commercial_insurance_policy',
      '/experience/commercial_insurance_policy',
      '/document/commercial_insurance_policy',
      '/market/detail/tax_payment_certificate',
      '/market/chager/tax_payment_certificate',
      '/experience/tax_payment_certificate',
      '/document/tax_payment_certificate',
      '/market/detail/blockchain_validate',
      '/market/chager/blockchain_validate',
      '/experience/blockchain_validate',
      '/document/blockchain_validate',
    ],
  },
  [textinCard]: {
    codeImage: '/images/qrcode/textin_card.png',
    paths: [
      '/product/textin_card',
      '/market/detail/id_card',
      '/market/chager/id_card',
      '/experience/id_card',
      '/document/id_card',
      '/market/detail/business_license',
      '/market/chager/business_license',
      '/experience/business_license',
      '/document/business_license',
      '/market/detail/bank_card',
      '/market/chager/bank_card',
      '/experience/bank_card',
      '/document/bank_card',
      '/market/detail/vehicle_license',
      '/market/chager/vehicle_license',
      '/experience/vehicle_license',
      '/document/vehicle_license',
      '/market/detail/driver_license',
      '/market/chager/driver_license',
      '/experience/driver_license',
      '/document/driver_license',
      '/market/detail/open_kie_credentials',
      '/market/chager/open_kie_credentials',
      '/experience/open_kie_credentials',
      '/document/open_kie_credentials',
      '/market/detail/vehicle_inspection_certificate',
      '/market/chager/vehicle_inspection_certificate',
      '/experience/vehicle_inspection_certificate',
      '/document/vehicle_inspection_certificate',
      '/market/detail/business_card',
      '/market/chager/business_card',
      '/experience/business_card',
      '/document/business_card',
      '/market/detail/social_security_card',
      '/market/chager/social_security_card',
      '/experience/social_security_card',
      '/document/social_security_card',
      '/market/detail/passport',
      '/market/chager/passport',
      '/experience/passport',
      '/document/passport',
      '/market/detail/vehicle_vin_code',
      '/market/chager/vehicle_vin_code',
      '/experience/vehicle_vin_code',
      '/document/vehicle_vin_code',
      '/market/detail/vehicle_registration_certificate',
      '/market/chager/vehicle_registration_certificate',
      '/experience/vehicle_registration_certificate',
      '/document/vehicle_registration_certificate',
      '/market/detail/cert_classify',
      '/market/chager/cert_classify',
      '/experience/cert_classify',
      '/document/cert_classify',
      '/market/detail/tax_registration_certificate',
      '/market/chager/tax_registration_certificate',
      '/experience/tax_registration_certificate',
      '/document/tax_registration_certificate',
      '/market/detail/organization_code_certificate',
      '/market/chager/organization_code_certificate',
      '/experience/organization_code_certificate',
      '/document/organization_code_certificate',
      '/market/detail/account_opening_permit',
      '/market/chager/account_opening_permit',
      '/experience/account_opening_permit',
      '/document/account_opening_permit',
      '/market/detail/mac_id_card',
      '/market/chager/mac_id_card',
      '/experience/mac_id_card',
      '/document/mac_id_card',
      '/market/detail/hk_mac_mainland_travel_permit',
      '/market/chager/hk_mac_mainland_travel_permit',
      '/experience/hk_mac_mainland_travel_permit',
      '/document/hk_mac_mainland_travel_permit',
      '/market/detail/taiwan_compatriot',
      '/market/chager/taiwan_compatriot',
      '/experience/taiwan_compatriot',
      '/document/taiwan_compatriot',
      '/market/detail/taiwan_idcard',
      '/market/chager/taiwan_idcard',
      '/experience/taiwan_idcard',
      '/document/taiwan_idcard',
      '/market/detail/malaysia_id_card',
      '/market/chager/malaysia_id_card',
      '/experience/malaysia_id_card',
      '/document/malaysia_id_card',
      '/market/detail/household_register',
      '/market/chager/household_register',
      '/experience/household_register',
      '/document/household_register',
      '/market/detail/marriage_certificate',
      '/market/chager/marriage_certificate',
      '/experience/marriage_certificate',
      '/document/marriage_certificate',
      '/market/detail/property_certificate',
      '/market/chager/property_certificate',
      '/experience/property_certificate',
      '/document/property_certificate',
      '/market/detail/military_id_card',
      '/market/chager/military_id_card',
      '/experience/military_id_card',
      '/document/military_id_card',
      '/market/detail/institution_legal_person',
      '/market/chager/institution_legal_person',
      '/experience/institution_legal_person',
      '/document/institution_legal_person',
      '/market/detail/hk_mac_exit_entry_permit',
      '/market/chager/hk_mac_exit_entry_permit',
      '/experience/hk_mac_exit_entry_permit',
      '/document/hk_mac_exit_entry_permit',
      '/market/detail/jpn_driver_license',
      '/market/chager/jpn_driver_license',
      '/experience/jpn_driver_license',
      '/document/jpn_driver_license',
      '/market/detail/hk_id_card',
      '/market/chager/hk_id_card',
      '/experience/hk_id_card',
      '/document/hk_id_card',
      '/market/detail/birth_certificate',
      '/market/chager/birth_certificate',
      '/experience/birth_certificate',
      '/document/birth_certificate',
      '/market/detail/real_estate_certificate',
      '/market/chager/real_estate_certificate',
      '/experience/real_estate_certificate',
      '/document/real_estate_certificate',
      '/market/detail/hkmctw_residence_permit',
      '/market/chager/hkmctw_residence_permit',
      '/experience/hkmctw_residence_permit',
      '/document/hkmctw_residence_permit',
      '/market/detail/foreign_permanent_resident_id_card',
      '/market/chager/foreign_permanent_resident_id_card',
      '/experience/foreign_permanent_resident_id_card',
      '/document/foreign_permanent_resident_id_card',
      '/market/detail/graduation-certificate',
      '/market/chager/graduation-certificate',
      '/experience/graduation-certificate',
      '/document/graduation-certificate',
      '/market/detail/degree-certificate',
      '/market/chager/degree-certificate',
      '/experience/degree-certificate',
      '/document/degree-certificate',
    ],
  },
  [textinConversion]: {
    codeImage: '/images/qrcode/textin_conversion.png',
    paths: [
      '/product/textin_conversion',
      '/market/detail/doc_restore',
      '/market/chager/doc_restore',
      '/experience/doc_restore',
      '/document/doc_restore',
      '/market/detail/word-to-image',
      '/market/chager/word-to-image',
      '/experience/word-to-image',
      '/document/word-to-image',
      '/market/detail/image-to-pdf',
      '/market/chager/image-to-pdf',
      '/experience/image-to-pdf',
      '/document/image-to-pdf',
      '/market/detail/excel-to-pdf',
      '/market/chager/excel-to-pdf',
      '/experience/excel-to-pdf',
      '/document/excel-to-pdf',
      '/market/detail/word-to-pdf',
      '/market/chager/word-to-pdf',
      '/experience/word-to-pdf',
      '/document/word-to-pdf',
      '/market/detail/pdf-to-image',
      '/market/chager/pdf-to-image',
      '/experience/pdf-to-image',
      '/document/pdf-to-image',
      '/market/detail/pdf-to-ppt',
      '/market/chager/pdf-to-ppt',
      '/experience/pdf-to-ppt',
      '/document/pdf-to-ppt',
      '/market/detail/pdf-to-excel',
      '/market/chager/pdf-to-excel',
      '/experience/pdf-to-excel',
      '/document/pdf-to-excel',
      '/market/detail/pdf-to-word',
      '/market/chager/pdf-to-word',
      '/experience/pdf-to-word',
      '/document/pdf-to-word',
      '/market/detail/ofd-to-image',
      '/market/chager/ofd-to-image',
      '/experience/ofd-to-image',
      '/document/ofd-to-image',
    ],
  },
  [recognizeTable]: {
    codeImage: '/images/qrcode/recognize_table.png',
    paths: [
      '/market/detail/recognize_table_multipage',
      '/market/chager/recognize_table_multipage',
      '/experience/recognize_table_multipage',
      '/document/recognize_table_multipage',
    ],
  },
  [textinIntfinq]: {
    codeImage: '/images/qrcode/textin_intfinq.png',
    paths: ['/product/textin_intfinq'],
  },
  [others]: {
    codeImage: '/images/qrcode/others.png',
  },
};

export const getQrCodeByUrlPath = (path: string) => {
  const qrCodeInfo = Object.values(qrcodeConfig).find((item) => item.paths?.includes(path));
  return (qrCodeInfo || qrcodeConfig[others]).codeImage;
};
