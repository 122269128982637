import { PhoneReg, emailRegEx } from './static';
class Verify {
  isPhoneNumber(num: string | number) {
    return PhoneReg.test(String(num));
  }
  isValidEmail(email: string) {
    return emailRegEx.test(String(email));
  }
  isValidURL(url: string) {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  }
}
export default new Verify();
