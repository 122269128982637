import type { FC } from 'react';
import { useMemo, useState, useEffect } from 'react';
import { useRequest } from 'ahooks';
import { Form, Input, Select, Cascader } from 'antd';
import dayjs from 'dayjs';
import { get } from 'lodash';
import verify from '@/utils/verify';
import { getLocalUserInfo } from '@/utils';
import { track } from '@/utils/track';
import type { IUserInfo } from '@/service/common/Index';
import { getUserInfo, updateUserInfo, updateUserCompany } from '@/service/common/Index';
import CompanySelect from '@/components/common/CompanSelect';
import { CertificationStatus } from '@/components/Header/component/UserInfo/EnterpriseCertificationStatus/constant';
import SubmitButton from '../components/SubmitButton';
import { amountOptions, currentPortraitsActivity, roleOptions, sceneOptions } from './config';
import { updateLeadsInfo } from './helpers';
import HeaderMobile from './HeaderMobile';
import { showWechatMobileModal } from './WechatMobile';
import styles from './DemandsMobile.module.scss';
import { useRouter } from 'next/router';
import { userModelContainer } from '@/store/userModel';

interface DemandsData {
  demands: {
    role?: string;
    scene?: string;
    document?: string;
    document_desc?: string;
    amount?: string;
  };
  profile: {
    company?: string;
    department?: string;
    email?: string;
  };
}

const requriedFields = [
  'demands.role',
  'demands.scene',
  'demands.document',
  'demands.amount',
  'profile.company',
  'profile.department',
  'profile.email',
];

const separator = '，';

const transformFormData = (data: any) => {
  const { demands, ...rest } = data || {};
  return {
    ...rest,
    demands: {
      ...demands,
      document: demands?.document
        ?.map((item) => {
          if (Array.isArray(item)) {
            return item.join('/');
          }
          return item;
        })
        .join(separator),
    },
  };
};

const lastTimeShowDemandsModalCackeKey = () =>
  `lastTimeShowDemandsModal_${getLocalUserInfo()?.account || ''}`;

export let isDemandsModalOpen = false;

const DemandsMobile: FC<{
  onClose?: () => void;
  onSubmit?: (data: any) => void;
}> = ({ onClose, onSubmit }) => {
  const router = useRouter();
  const { saveUserInfo } = userModelContainer.useContainer();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<DemandsData>({ demands: {}, profile: {} });
  const [company, setCompany] = useState<any>();
  const [uploading, setUploading] = useState(false);
  const showDocumentDesc = (formData?.demands?.document || '')
    .split(separator)
    .some((item) => ['拍照图片', '其他'].includes(item));
  const btnDisabled = useMemo(() => requriedFields.some((key) => !get(formData, key)), [formData]);

  const { data: res } = useRequest(getUserInfo);
  const serverUserInfo = res?.data;
  const localUserInfo = getLocalUserInfo();
  const userInfo = serverUserInfo || localUserInfo;

  const accountCompany = userInfo?.company || undefined;
  const isAccountCompanyVerified =
    (userInfo?.enterprise_auth_status ?? userInfo?.enterpriseAuthStatus) ===
    CertificationStatus.success;
  const accountEmail = userInfo?.email;

  useEffect(() => {
    if (accountCompany || accountEmail) {
      form.setFieldsValue({
        profile: {
          company: accountCompany,
          email: accountEmail,
        },
      });
    }
  }, [accountCompany, accountEmail]);

  const handleValuesChange = (changedValues, allValues) => {
    console.log({ changedValues });
    setFormData(transformFormData(allValues));
  };

  const handleUpdateUserInfo = async (userPrefered: any, currentUserInfo?: IUserInfo['data']) => {
    await updateUserInfo({
      user_prefered_activity: currentPortraitsActivity().value,
      user_prefered: JSON.stringify(userPrefered),
    }).then(() => {
      track({
        keyword: '线索留存【线索成功提交】',
        url: window.location.href,
        提交线索板块: '信息1',
        device: 'mo',
      });
    });
    saveUserInfo({ userPrefered });
    onSubmit?.({ userPrefered });
    // 上报线索系统
    const leadsIds = currentUserInfo?.clue_ids;
    updateLeadsInfo(leadsIds, userPrefered);

    // 同步更新用户账号信息
    if (!(currentUserInfo || userInfo)?.company && userPrefered?.profile?.company) {
      await updateUserCompany(userPrefered?.profile?.company);
    }
  };

  const handleClose = () => {
    isDemandsModalOpen = false;
    onClose?.();
    const redirect = router.query.redirect;
    if (typeof redirect === 'string') {
      try {
        if (!showWechatMobileModal({ redirect })) {
          window.open(decodeURIComponent(redirect), '_self');
        }
      } catch (error) {
        router.replace('/');
      }
    } else {
      router.back();
    }
  };

  const handleSubmit = () => {
    form.validateFields().then(async (formValues) => {
      try {
        setUploading(true);
        const data = transformFormData(formValues);
        const profileData = { ...data.profile, company_id: company?.id };
        await getUserInfo()
          .then((res) => {
            const oldUserPrefered = res.data.user_prefered;
            const newUserPrefered = {
              ...(oldUserPrefered || {}),
              ...data,
              profile: profileData,
            };
            return handleUpdateUserInfo(newUserPrefered, res.data);
          })
          .catch(() => {
            return handleUpdateUserInfo(data);
          });
      } catch (error) {
        console.error(error);
      } finally {
        handleClose();
        setUploading(false);
      }
    });
  };

  return (
    <div className={styles.container} id="container">
      <HeaderMobile />

      <div className={styles.title} id="title">
        完善信息，
        <br />
        加赠 TextIn<span className={styles.titleStrong}>1000次/页</span>体验
      </div>
      <div id="desc" className={styles.desc}>
        我们将根据您提供的信息，为您开通更精准的产品体验包
      </div>
      <Form
        className={styles.form}
        form={form}
        layout="vertical"
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          name={['demands', 'role']}
          label="您的角色"
          required={requriedFields.includes('demands.role')}
          rules={[
            {
              required: requriedFields.includes('demands.role'),
              message: '请选择您的角色',
            },
          ]}
        >
          <Select placeholder="请选择您的角色" options={roleOptions} />
        </Form.Item>
        <Form.Item
          name={['demands', 'document']}
          label="您的文档类型"
          required={requriedFields.includes('demands.document')}
          rules={[
            {
              required: requriedFields.includes('demands.document'),
              message: '请选择您的文档类型',
            },
          ]}
        >
          <Cascader
            placeholder="请选择您常需要处理的文档类型"
            options={sceneOptions}
            allowClear
            multiple
            dropdownClassName={styles.cascaderDropdown}
          />
        </Form.Item>
        <Form.Item
          name={['demands', 'document_desc']}
          label="请具体描述您的文档类型"
          hidden={!showDocumentDesc}
          required={requriedFields.includes('demands.document') && showDocumentDesc}
          rules={[
            {
              required: requriedFields.includes('demands.document') && showDocumentDesc,
              message: '请具体描述您的文档类型',
            },
          ]}
        >
          <Input placeholder="请具体描述您的文档类型" maxLength={1000} />
        </Form.Item>
        <Form.Item
          name={['demands', 'scene']}
          label="您的使用场景"
          required={requriedFields.includes('demands.scene')}
          rules={[
            {
              required: requriedFields.includes('demands.scene'),
              message: '请输入您的使用场景',
            },
          ]}
        >
          <Input placeholder="请简述您的使用场景，如提取年报中的财务数据" maxLength={1000} />
        </Form.Item>
        <Form.Item
          name={['demands', 'amount']}
          label="您的预估调用量（每月）"
          required={requriedFields.includes('demands.amount')}
          rules={[
            {
              required: requriedFields.includes('demands.amount'),
              message: '请选择您的调用量',
            },
          ]}
        >
          <Select placeholder="请选择您每月的预估调用量" options={amountOptions} />
        </Form.Item>
        <Form.Item
          name={['profile', 'company']}
          label="您所在企业或组织名称"
          required={requriedFields.includes('profile.company')}
          rules={[
            {
              required: requriedFields.includes('profile.company'),
              message: '请输入您的企业或组织名称',
            },
          ]}
        >
          <CompanySelect
            disabled={accountCompany && isAccountCompanyVerified}
            onSelected={(value) => {
              setCompany(value);
            }}
            placeholder="请输入您的企业或组织名称"
          />
        </Form.Item>
        <Form.Item
          name={['profile', 'department']}
          label="您的部门或职位"
          required={requriedFields.includes('profile.department')}
          rules={[
            {
              required: requriedFields.includes('profile.department'),
              message: '请输入您的部门或职位',
            },
          ]}
        >
          <Input placeholder="请输入您的部门或职位" maxLength={100} />
        </Form.Item>
        <Form.Item
          name={['profile', 'email']}
          label="您的邮箱"
          required={requriedFields.includes('profile.email')}
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject('请输入您的邮箱');
                }
                if (!verify.isValidEmail(value)) {
                  return Promise.reject('请输入有效邮箱号');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="请输入您的常用邮箱，我们将为您充值到账！" maxLength={100} />
        </Form.Item>
        <div className={styles.moreTips}>
          更多需求，您可致电
          <a href="tel:400-6666-582" className={styles.titleStrong}>
            400-6666-582
          </a>
          进行咨询
        </div>
      </Form>
      <div className={styles.footer}>
        <div className={styles.closeText} onClick={handleClose}>
          稍后填写
        </div>
        <SubmitButton
          className={styles.btn}
          loading={uploading}
          active={!btnDisabled}
          style={{ width: '60%' }}
          onClick={handleSubmit}
        >
          开始使用
        </SubmitButton>
      </div>
    </div>
  );
};

export const showDemandsMobileModal = ({
  force = false,
}: {
  force?: boolean;
  onClose?: () => void;
  onSubmit?: (data: any) => void;
} = {}) => {
  if (!force) {
    const lastTimeShowDemandsModal = localStorage.getItem(lastTimeShowDemandsModalCackeKey());
    if (dayjs().diff(dayjs(lastTimeShowDemandsModal), 'day') < 1) {
      return;
    }
  }
  isDemandsModalOpen = true;
  if (!force) {
    localStorage.setItem(lastTimeShowDemandsModalCackeKey(), dayjs().toJSON());
  }

  track({
    keyword: '线索留存【完善信息1】（曝光）',
    url: window.location.href,
    trigger: force ? '右侧悬浮窗' : '登录流程',
    device: 'mo',
  });
  window.open(
    `/user/portraits/demands?redirect=${encodeURIComponent(window.location.href)}`,
    '_self',
  );
};

export default DemandsMobile;
