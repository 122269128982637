import { extend } from 'umi-request';
import nookies from 'nookies';
import { reqEnvUrl, getEnv } from './env';
import { isBrowser } from './common';

const tokenCookieKey = '__user_client_uid';

export const to_base64 = (val: string) => {
  if (isBrowser()) {
    return window.btoa(val);
  } else {
    return Buffer.from(val).toString('base64');
  }
};

export function setTokenCookie(token: any, res?: any) {
  const tokenStr = to_base64(token.slice(18) + token.slice(0, 18));
  nookies.set(res, tokenCookieKey, tokenStr, { maxAge: 7 * 24 * 60 * 60, path: '/' });
  nookies.destroy(res, '_token');
}

export function getTokenCookie(ctx: any) {
  if (ctx.req.headers.token) return ctx.req.headers.token;
  const originToken = nookies.get(ctx)[tokenCookieKey];
  if (!originToken) return '';
  const tokenStr = Buffer.from(originToken, 'base64').toString('utf-8');
  return tokenStr.slice(tokenStr.length - 18) + tokenStr.slice(0, tokenStr.length - 18);
}

const serverRequest = (ctx: any, token?: boolean) => {
  const req = ctx && ctx.req ? ctx.req : ctx;
  const { host } = req.headers;
  const getRequestEnvUrl = () => reqEnvUrl[getEnv(host)];
  const request = extend({
    prefix: getRequestEnvUrl(),
    isServer: true,
    headers: {
      token: token ? getTokenCookie({ req }) : '',
    },
    errorHandler: (error) => errorHandler(error, ctx),
    timeout: 1000 * 2, // 服务端请求超时
  });
  return request;
};

function errorHandler(error, ctx) {
  const { data, request } = error;
  if (data?.code === 401 && !ctx.serverRetry) {
    return new Promise((resolve, reject) => {
      try {
        const options = { ...request.options };
        ctx.serverRetry = true;
        options.headers.token = '';
        setTokenCookie('', ctx);
        resolve(extend(options)(options.url));
      } catch (err) {
        reject(err);
      }
    });
  }
  throw error;
}

export default serverRequest;
