import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Form, Input, Modal } from 'antd';
import dayjs from 'dayjs';
import verify from '@/utils/verify';
import type { IUserInfo } from '@/service/common/Index';
import { getUserInfo, updateUserCompany, updateUserInfo } from '@/service/common/Index';
import SubmitButton from '../components/SubmitButton';
import styles from './Profile.module.scss';
import { showWechatModal } from './Wechat';
import { getLocalUserInfo } from '@/utils';
import { track } from '@/utils/track';
import CompanySelect from '@/components/common/CompanSelect';
import { currentPortraitsActivity } from './config';
import { updateLeadsInfo } from './helpers';
import { CertificationStatus } from '@/components/Header/component/UserInfo/EnterpriseCertificationStatus/constant';
import { useRequest } from 'ahooks';

interface ProfileData {
  company?: string;
  department?: string;
  email?: string;
}

const requriedFields = ['company', 'department', 'email'];

const transformFormData = (data: any) => {
  return data;
};

const Profile: FC<{ onClose?: () => void; onSubmit?: (data: any) => void }> = ({
  onClose,
  onSubmit,
}) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<ProfileData>({});
  const [company, setCompany] = useState<any>();

  const btnDisabled = useMemo(() => requriedFields.some((key) => !formData?.[key]), [formData]);

  const { data: res } = useRequest(getUserInfo);
  const serverUserInfo = res?.data;
  const localUserInfo = getLocalUserInfo();
  const userInfo = serverUserInfo || localUserInfo;

  const accountCompany = userInfo?.company || undefined;
  const isAccountCompanyVerified =
    (userInfo?.enterprise_auth_status ?? userInfo?.enterpriseAuthStatus) ===
    CertificationStatus.success;
  const accountEmail = userInfo?.email;

  useEffect(() => {
    if (accountCompany || accountEmail) {
      form.setFieldsValue({
        company: accountCompany,
        email: accountEmail,
      });
    }
  }, [accountCompany, accountEmail]);

  const handleValuesChange = (changedValues, allValues) => {
    console.log({ changedValues });
    setFormData(transformFormData(allValues));
  };

  const handleUpdateUserInfo = (userPrefered: any, currentUserInfo?: IUserInfo['data']) => {
    updateUserInfo({
      user_prefered_activity: currentPortraitsActivity().value,
      user_prefered: JSON.stringify(userPrefered),
    }).then(() => {
      track({
        keyword: '线索留存【线索成功提交】',
        url: window.location.href,
        提交线索板块: '信息2',
        device: 'pc',
      });
    });
    onSubmit?.({ userPrefered });

    // 上报线索系统
    const leadsIds = currentUserInfo?.clue_ids;
    updateLeadsInfo(leadsIds, userPrefered);

    // 同步更新用户账号信息
    if (!(currentUserInfo || userInfo)?.company && userPrefered?.profile?.company) {
      updateUserCompany(userPrefered?.profile?.company);
    }
  };

  const handleSubmit = () => {
    form.validateFields().then(async (formValues) => {
      try {
        const data = transformFormData(formValues);
        const profileData = { ...data, company_id: company?.id };
        getUserInfo()
          .then((res) => {
            const oldUserPrefered = res.data.user_prefered;
            const newUserPrefered = {
              ...(oldUserPrefered || {}),
              profile: profileData,
            };
            handleUpdateUserInfo(newUserPrefered, res.data);
          })
          .catch(() => {
            handleUpdateUserInfo({ profile: profileData });
          });
      } catch (error) {
        console.error(error);
      } finally {
        onClose?.();
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>欢迎来到 TextIn 智能文档平台</div>
      <div className={styles.desc}>请提交以下信息，便于我们将加赠额度充入您的账户</div>
      <Form form={form} layout="vertical" onValuesChange={handleValuesChange}>
        <Form.Item
          name="company"
          label="您所在企业或组织名称"
          required={requriedFields.includes('company')}
          rules={[
            {
              required: requriedFields.includes('company'),
              message: '请输入您的企业或组织名称',
            },
          ]}
        >
          {/* <Input placeholder="请输入您的企业或组织名称" /> */}
          <CompanySelect
            disabled={accountCompany && isAccountCompanyVerified}
            onSelected={(value) => {
              setCompany(value);
            }}
            placeholder="请输入您的企业或组织名称"
          />
        </Form.Item>
        <Form.Item
          name="department"
          label="您的部门或职位"
          required={requriedFields.includes('department')}
          rules={[
            {
              required: requriedFields.includes('department'),
              message: '请输入您的部门或职位',
            },
          ]}
        >
          <Input placeholder="请输入您的部门或职位" maxLength={100} />
        </Form.Item>
        <Form.Item
          name="email"
          label="您的邮箱"
          required={requriedFields.includes('email')}
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject('请输入您的邮箱');
                }
                if (!verify.isValidEmail(value)) {
                  return Promise.reject('请输入有效邮箱号');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="请输入您的常用邮箱，我们将为您充值到账！" maxLength={100} />
        </Form.Item>
      </Form>
      <SubmitButton
        className={styles.btn}
        active={!btnDisabled}
        style={{ width: '100%' }}
        onClick={handleSubmit}
      >
        开始使用
      </SubmitButton>
    </div>
  );
};

const lastTimeShowProfileModalCackeKey = () =>
  `lastTimeShowProfileModal_${getLocalUserInfo()?.account || ''}`;

export let isProfileModalOpen = false;

export const showProfileModal = ({
  force = false,
  onClose,
  onSubmit,
}: { force?: boolean; onClose?: () => void; onSubmit?: (data: any) => void } = {}) => {
  if (!force) {
    const lastTimeShowProfileModal = localStorage.getItem(lastTimeShowProfileModalCackeKey());
    if (dayjs().diff(dayjs(lastTimeShowProfileModal), 'day') < 1) {
      return;
    }
  }

  const modal = Modal.confirm({
    className: styles.modal,
    maskStyle: {
      backdropFilter: 'blur(5px)',
      WebkitBackdropFilter: 'blur(5px)',
      backgroundColor: '#141f334d',
    },
    centered: true,
    icon: null,
    content: <Profile onClose={handleClose} onSubmit={onSubmit} />,
    width: 600,
    closable: true,
    onCancel: () => {
      isProfileModalOpen = false;
      showWechatModal();
    },
  });
  isProfileModalOpen = true;
  function handleClose() {
    isProfileModalOpen = false;
    modal.destroy();
    showWechatModal();
    onClose?.();
  }
  if (!force) {
    localStorage.setItem(lastTimeShowProfileModalCackeKey(), dayjs().toJSON());
  }
  track({
    keyword: '线索留存【完善信息2】（曝光）',
    url: window.location.href,
    trigger: force ? '右侧悬浮窗' : '登录流程',
    device: 'pc',
  });
};

export default Profile;
