import styles from './index.module.less';

const NumberInfo = ({ onClick }: { onClick?: () => void }) => {
  return (
    <div className={styles['number-wrapper']} onClick={onClick}>
      <div className={styles.mouseWrapper}>
        <div className={styles.mouse} />
        <p className={styles.text}>向下划动 查看更多</p>
      </div>
    </div>
  );
};

export default NumberInfo;
