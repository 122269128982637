import type { IResponse } from '@/utils/request';
import request from '@/utils/request';
import type { RequestOptionsInit } from 'umi-request';
import { transformUserInfoFromApi } from './transformers';

export interface ILogoutResponse extends IResponse {
  data: {
    /**
     * @name 是否通过sso登录系统
     * @description
     * 1 是
     * 0 否
     */
    is_sso: 1 | 0;
    /**
     * @description sso platFormId
     */
    platform_id: string;
  };
}
export async function logout() {
  return request<ILogoutResponse>('/user/logout', {
    method: 'POST',
  });
}

export interface IUserInfo extends IResponse {
  data: {
    account: string;
    name: string;
    role: string;
    token: string;
    [key: string]: any;
  };
}

export async function getUserInfo() {
  return request
    .post<IUserInfo>('/service/user/info', {
      data: { with_app: 1 },
    })
    .then((res) => {
      return transformUserInfoFromApi(res);
    });
}

export async function updateUserInfo(data: Record<string, any>) {
  return request.post<IUserInfo>('/service/user/update', {
    data,
  });
}

export async function updateUserCompany(company: string = '') {
  return request<IResponse>('/service/user/update_company', {
    method: 'POST',
    data: {
      company,
    },
  });
}

export interface IBlanceProp {
  balance: number;
  availableBalance: number;
  unavailableBalance: number;
  unavailable_balance: number;
  postpaidAmount: number; // 后付费额度上限
  unpaid: number; // 待支付额度
}
export interface IBalanceResponse extends IResponse {
  data: IBlanceProp;
}

export async function getBalance() {
  return request<IBalanceResponse>('/user/finance/balance');
}

export const uploadFile = (params) =>
  request.post('/image/upload', {
    data: params,
  });

/**
 *
 * @param path 资源完整地址，或者 filename 值
 */
export const getFile = (path: string) => {
  const opts: RequestOptionsInit = { responseType: 'blob' };
  let url: string = path;
  if (path.startsWith('http')) {
    opts.prefix = '';
  } else {
    url = `/image/download?filename=${path}`;
  }
  return request.get(url, opts);
};

export const postActivate = (code: string) => request.post<IResponse>(`/activate/${code}`);

export interface ICompanyListItem {
  name: string;
  id: string;
  [key: string]: any;
}

interface IQueryCompanyRes extends IResponse {
  data: {
    list: ICompanyListItem[];
  };
}
export async function queryCompanyFromQixin(keyword: string) {
  return request.get<IQueryCompanyRes>(`/service/qixin/search?keyword=${keyword || '""'}`);
}
