import Image from 'next/image';
import styles from './index.module.less';
import { toDocFlow } from '@/containers/Product/dataSource/docflow';
import { toContract } from '@/containers/Product/dataSource';
import { toIntFinQ } from '@/containers/Product/dataSource/knowledge_base';
import { track } from '@/utils/track';

export default function SceneList() {
  const handleTrack = (type: string) => {
    track({ keyword: `导航栏【体验中心】`, product_type: type });
  };
  return (
    <div className={styles.container}>
      <div
        className={styles.item}
        onClick={() => {
          handleTrack('DocFlow票据自动化');
          toDocFlow();
        }}
      >
        <SceneIcon path="/images/experience/popover/icon-DocFlow@2x.png" />
        <span>DocFlow票据自动化</span>
      </div>
      <div
        className={styles.item}
        onClick={() => {
          handleTrack('合同比对');
          toContract('compare');
        }}
      >
        <SceneIcon path="/images/experience/popover/icon-ContractCompare@2x.png" />
        <span>合同比对</span>
      </div>
      <div
        className={styles.item}
        onClick={() => {
          handleTrack('合同抽取');
          toContract('extraction');
        }}
      >
        <SceneIcon path="/images/experience/popover/icon-ContractExtraction@2x.png" />
        <span>合同抽取</span>
      </div>
      <div
        className={styles.item}
        onClick={() => {
          handleTrack('知识管理及写作助手');
          toIntFinQ();
        }}
      >
        <SceneIcon path="/images/experience/popover/icon-Intfinq@2x.png" />
        <span>知识管理及写作助手</span>
      </div>
    </div>
  );
}

function SceneIcon({ path }: { path: string }) {
  return <Image src={path} width={24} height={24} alt="" />;
}
