import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { Form, Input, Select, Cascader, Modal } from 'antd';
import dayjs from 'dayjs';
import type { IUserInfo } from '@/service/common/Index';
import { getUserInfo, updateUserInfo } from '@/service/common/Index';
import SubmitButton from '../components/SubmitButton';
import { amountOptions, currentPortraitsActivity, roleOptions, sceneOptions } from './config';
import styles from './Demands.module.scss';
import { showWechatModal } from './Wechat';
import { showProfileModal } from './Profile';
import { getLocalUserInfo } from '@/utils';
import { track } from '@/utils/track';
import { updateLeadsInfo } from './helpers';

interface DemandsData {
  role?: string;
  scene?: string;
  document?: string;
  document_desc?: string;
  amount?: string;
}

const requriedFields = ['role', 'scene', 'document', 'amount'];

const separator = '，';

const transformFormData = (data: any) => {
  return {
    ...(data || {}),
    document: data?.document
      ?.map((item) => {
        if (Array.isArray(item)) {
          return item.join('/');
        }
        return item;
      })
      .join(separator),
  };
};

const Demands: FC<{ showClose?: boolean; onClose?: () => void; onSubmit?: (data: any) => void }> =
  ({ showClose = true, onClose, onSubmit }) => {
    const [form] = Form.useForm();
    const [formData, setFormData] = useState<DemandsData>({});
    const showDocumentDesc = (formData?.document || '')
      .split(separator)
      .some((item) => ['拍照图片', '其他'].includes(item));
    const btnDisabled = useMemo(() => requriedFields.some((key) => !formData?.[key]), [formData]);

    const handleValuesChange = (changedValues, allValues) => {
      console.log({ changedValues });
      setFormData(transformFormData(allValues));
    };

    const handleUpdateUserInfo = (userPrefered: any, currentUserInfo?: IUserInfo['data']) => {
      updateUserInfo({
        user_prefered_activity: currentPortraitsActivity().value,
        user_prefered: JSON.stringify(userPrefered),
      }).then(() => {
        track({
          keyword: '线索留存【线索成功提交】',
          url: window.location.href,
          提交线索板块: '信息1',
          device: 'pc',
        });
      });
      onSubmit?.({ userPrefered });
      // 上报线索系统
      const leadsIds = currentUserInfo?.clue_ids;
      updateLeadsInfo(leadsIds, userPrefered);
    };

    const handleSubmit = () => {
      form.validateFields().then(async (formValues) => {
        try {
          const data = transformFormData(formValues);
          getUserInfo()
            .then((res) => {
              const oldUserPrefered = res.data.user_prefered;
              const newUserPrefered = {
                ...(oldUserPrefered || {}),
                demands: data,
              };
              handleUpdateUserInfo(newUserPrefered, res.data);
            })
            .catch(() => {
              handleUpdateUserInfo({ demands: data });
            });
        } catch (error) {
          console.error(error);
        } finally {
          onClose?.();
        }
      });
    };

    return (
      <div className={styles.container} id="container">
        {showClose && (
          <div className={styles.close} onClick={onClose}>
            关闭
          </div>
        )}
        <div className={styles.title} id="title">
          完善信息，加赠 TextIn<span className={styles.titleStrong}>1000次/页</span>体验
        </div>
        <div id="desc" className={styles.desc}>
          我们将根据您提供的信息，为您开通更精准的产品体验包
        </div>
        <Form form={form} layout="vertical" onValuesChange={handleValuesChange}>
          <Form.Item
            name="role"
            label="您的角色"
            required={requriedFields.includes('role')}
            rules={[
              {
                required: requriedFields.includes('role'),
                message: '请选择您的角色',
              },
            ]}
          >
            <Select placeholder="请选择您的角色" options={roleOptions} />
          </Form.Item>
          <Form.Item
            name="document"
            label="您的文档类型"
            required={requriedFields.includes('document')}
            rules={[
              {
                required: requriedFields.includes('document'),
                message: '请选择您的文档类型',
              },
            ]}
          >
            <Cascader
              placeholder="请选择您常需要处理的文档类型"
              options={sceneOptions}
              allowClear
              multiple
            />
          </Form.Item>
          <Form.Item
            name="document_desc"
            label="请具体描述您的文档类型"
            hidden={!showDocumentDesc}
            required={requriedFields.includes('document') && showDocumentDesc}
            rules={[
              {
                required: requriedFields.includes('document') && showDocumentDesc,
                message: '请具体描述您的文档类型',
              },
            ]}
          >
            <Input placeholder="请具体描述您的文档类型" maxLength={1000} />
          </Form.Item>
          <Form.Item
            name="scene"
            label="您的使用场景"
            required={requriedFields.includes('scene')}
            rules={[
              {
                required: requriedFields.includes('scene'),
                message: '请输入您的使用场景',
              },
            ]}
          >
            <Input placeholder="请简述您的使用场景，如提取年报中的财务数据" maxLength={1000} />
          </Form.Item>
          <Form.Item
            name="amount"
            label="您的预估调用量（每月）"
            required={requriedFields.includes('amount')}
            rules={[
              {
                required: requriedFields.includes('amount'),
                message: '请选择您的调用量',
              },
            ]}
          >
            <Select placeholder="请选择您每月的预估调用量" options={amountOptions} />
          </Form.Item>
        </Form>
        <SubmitButton
          className={styles.btn}
          active={!btnDisabled}
          style={{ width: '100%' }}
          onClick={handleSubmit}
        >
          下一步
        </SubmitButton>
      </div>
    );
  };

const lastTimeShowDemandsModalCackeKey = () =>
  `lastTimeShowDemandsModal_${getLocalUserInfo()?.account || ''}`;

export let isDemandsModalOpen = false;

export const showDemandsModal = ({
  force = false,
  onClose,
  onSubmit,
}: { force?: boolean; onClose?: () => void; onSubmit?: (data: any) => void } = {}) => {
  if (!force) {
    const lastTimeShowDemandsModal = localStorage.getItem(lastTimeShowDemandsModalCackeKey());
    if (dayjs().diff(dayjs(lastTimeShowDemandsModal), 'day') < 1) {
      return;
    }
  }

  const modal = Modal.confirm({
    className: styles.modal,
    maskStyle: {
      backdropFilter: 'blur(5px)',
      WebkitBackdropFilter: 'blur(5px)',
      backgroundColor: '#141f334d',
    },
    centered: true,
    icon: null,
    content: <Demands showClose={false} onClose={closeModal} onSubmit={onSubmit} />,
    width: 610,
    closable: force,
    onCancel: () => {
      isDemandsModalOpen = false;
      showWechatModal();
    },
  });
  isDemandsModalOpen = true;
  function closeModal() {
    isDemandsModalOpen = false;
    modal.destroy();
    showProfileModal({ force: true, onSubmit });
    onClose?.();
  }

  if (!force) {
    localStorage.setItem(lastTimeShowDemandsModalCackeKey(), dayjs().toJSON());
  }

  track({
    keyword: '线索留存【完善信息1】（曝光）',
    url: window.location.href,
    trigger: force ? '右侧悬浮窗' : '登录流程',
    device: 'pc',
  });
};

export default Demands;
