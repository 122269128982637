import { Fragment } from 'react';
import Banner from './Banner';
import Product from './Product';
import Scene from './Scene';
import Plan from './Plan';
import Customer from './ Customer';
import SignUpGuide from '@/components/SignUpGuide';
import { dashboardSitePrefixPath, getToken } from '@/utils';
import Link from '@/components/Link';
import WechatBanner from '@/components/Consult/WechatBanner';

const Home = () => {
  return (
    <Fragment>
      <Banner />
      <Product />
      <WechatBanner />
      <Scene />
      <Plan />
      <Customer />
      <SignUpGuide>
        <div
          onClick={(e) => {
            e.stopPropagation();
            window.open(`/experience/list`);
          }}
          className={'useBtn'}
        >
          免费使用
        </div>
        <Link href="/contact?type=15" passHref>
          <div className={'contactBtn'}>联系我们</div>
        </Link>
      </SignUpGuide>
    </Fragment>
  );
};

export default Home;
