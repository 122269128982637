import { useEffect } from 'react';
import { Router, useRouter } from 'next/router';
// import { getUrlSearchParams } from '../route';
// import { getExtraParams, getLeadsParams } from '../leads';
import { getUrlSearchParams, parseUrl, updateSearchParams, updateUrlQueryString } from '../route';
import { getToolsUrl } from '../env';

const preserveOriginList = () => [window.location.origin, getToolsUrl()];

const preservePathList = [
  '/experience',
  '/document',
  '/news',
  '/market',
  '/product',
  '/market/chager',
  '/user',
  '/contact',
  '/about',
];
const excludedPathList = ['/activity'];
const preserveKeys = ['from'];

const shouldPreserve = ({
  origin,
  pathname,
  originList,
  sourceUrl,
}: {
  origin?: string;
  pathname?: string;
  originList?: string[];
  sourceUrl?: string;
}) => {
  try {
    if (sourceUrl) {
      const searchParams = getUrlSearchParams(sourceUrl);
      if (!preserveKeys.some((key) => !!searchParams[key])) {
        return false;
      }
      const { pathname: sourcePathname } = parseUrl(sourceUrl);
      if (excludedPathList.some((path) => sourcePathname.startsWith(path))) {
        return false;
      }
    }
    if (!origin && !pathname) {
      return true;
    }
    return (
      (originList || preserveOriginList()).includes(origin || '') &&
      preservePathList.some((item) => pathname === '/' || (pathname || '').startsWith(item))
    );
  } catch (error) {
    return false;
  }
};

const convertUrlObjectToString = (url: any): string => {
  const { pathname, query } = url;
  const queryString = new URLSearchParams(query).toString();
  return `${pathname}${queryString ? `?${queryString}` : ''}`;
};

const addPreserveParamsToUrl = (url: string | URL) => {
  const urlObj = new URL(url, window.location.origin);
  if (
    !shouldPreserve({
      origin: urlObj.origin,
      pathname: urlObj.pathname,
      sourceUrl: window.location.href,
    })
  ) {
    return url;
  }

  const currentQuery = getUrlSearchParams();
  for (const [key, value] of Object.entries(currentQuery)) {
    if (preserveKeys.includes(key) && !urlObj.searchParams.has(key)) {
      urlObj.searchParams.append(key, String(value));
    }
  }

  return urlObj.toString();
};

export const addLeadsParamsToUrl = (
  sourceUrl: string = window.location.href,
  targetUrl?: string,
) => {
  if (!targetUrl) {
    return targetUrl;
  }
  try {
    const { origin, pathname } = parseUrl(targetUrl);
    if (!shouldPreserve({ origin, pathname, originList: ['', getToolsUrl()], sourceUrl })) {
      return targetUrl;
    }

    const searchParams = getUrlSearchParams(sourceUrl);
    if (searchParams.from) {
      return updateUrlQueryString({ from: searchParams.from }, targetUrl);
    }
  } catch (error) {}

  return targetUrl;
};

export const linkClickInterceptor = (target: HTMLAnchorElement | null) => {
  if (target?.href) {
    target.href = addPreserveParamsToUrl(target.href) as string;
  }
};

const handleLinkClick = (event: MouseEvent) => {
  const target = (event.target as HTMLElement).closest('a');
  try {
    linkClickInterceptor(target);
  } catch (error) {}
};

const usePreserveQuery = (): void => {
  //   useEffect(() => {
  //     const leadsParams = getLeadsParams();
  //     const currentParams = getUrlSearchParams();
  //     if (!currentParams.from && leadsParams.from) {
  //       updateSearchParams({ from: leadsParams.from });
  //     }
  //   }, []);

  const router = useRouter();

  // 矫正错误的redirect
  useEffect(() => {
    const { redirect } = router.query;
    if (redirect && redirect.includes('?')) {
      updateSearchParams({ redirect: encodeURIComponent(redirect as string) });
    }
  }, []);

  useEffect(() => {
    if (!shouldPreserve({ sourceUrl: window.location.href })) {
      return;
    }
    const handleRouteChange = (url: string) => {
      try {
        const currentHref = window.location.origin + url;
        let targetUrl = addPreserveParamsToUrl(url);
        const { origin: targetOrigin } = parseUrl(targetUrl as string);
        if (!targetOrigin) {
          targetUrl = window.location.origin + targetUrl;
        }
        if (currentHref !== targetUrl) {
          router.replace(targetUrl, undefined, { shallow: true });
        }
      } catch (error) {}
    };

    const originalRouterPush = router.push;
    router.push = async (...args) => {
      const [url, as, options] = args;
      try {
        const urlStr = typeof url === 'string' ? url : convertUrlObjectToString(url);
        const finalUrl = addPreserveParamsToUrl(urlStr);
        return await originalRouterPush(finalUrl, as, options);
      } catch (error) {
        console.error(error);
        return await originalRouterPush(...args);
      }
    };

    const originalRouterReplace = router.replace;
    router.replace = async (...args) => {
      const [url, as, options] = args;
      try {
        const urlStr = typeof url === 'string' ? url : convertUrlObjectToString(url);
        const finalUrl = addPreserveParamsToUrl(urlStr);
        return await originalRouterReplace(finalUrl, as, options);
      } catch (error) {
        console.error(error);
        return await originalRouterReplace(...args);
      }
    };

    const originalWindowOpen = window.open;
    window.open = (url?: string | URL, name?: string, specs?: string): Window | null => {
      let finalUrl = url;
      try {
        if (url) {
          finalUrl = addPreserveParamsToUrl(url);
        }
      } catch (error) {
        console.error(error);
      }
      return originalWindowOpen(finalUrl, name, specs);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    document.addEventListener('click', handleLinkClick);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.push = originalRouterPush;
      router.replace = originalRouterReplace;
      document.removeEventListener('click', handleLinkClick);
      window.open = originalWindowOpen;
    };
  }, [router]);
};

export default usePreserveQuery;
