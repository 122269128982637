// export function updateUrlQueryString(
//   queryParams: Record<string, string | null>,
//   url?: string,
// ): string {
//   const currentUrl = url || window.location.href;

//   const [baseUrl, queryString] = currentUrl.split('?');

//   const currentParams: Record<string, string> = queryString
//     ? queryString.split('&').reduce((acc, param) => {
//         const [k, v] = param.split('=');
//         acc[decodeURIComponent(k)] = decodeURIComponent(v);
//         return acc;
//       }, {} as Record<string, string>)
//     : {};

//   for (const key in queryParams) {
//     const value = queryParams[key];
//     if (value === null || value === undefined) {
//       delete currentParams[key];
//     } else {
//       currentParams[key] = value;
//     }
//   }

//   const newQueryString = Object.keys(currentParams)
//     .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(currentParams[k])}`)
//     .join('&');

//   return newQueryString ? `${baseUrl}?${newQueryString}` : baseUrl;
// }

export function updateUrlQueryString(
  queryParams: Record<string, string | null | undefined>,
  url?: string,
): string {
  const currentUrl = url || window.location.href;

  // Split URL and hash
  const [urlWithoutHash, hash] = currentUrl.split('#');
  const [baseUrl, queryString] = urlWithoutHash.split('?');

  // Parse current query parameters
  const currentParams: Record<string, string> = queryString
    ? queryString.split('&').reduce((acc, param) => {
        const [k, v] = param.split('=');
        if (k) {
          acc[decodeURIComponent(k)] = v ? decodeURIComponent(v) : '';
        }
        return acc;
      }, {} as Record<string, string>)
    : {};

  // Update query parameters
  for (const key in queryParams) {
    const value = queryParams[key];
    if (value === null || value === undefined) {
      delete currentParams[key];
    } else {
      currentParams[key] = value;
    }
  }

  // Construct new query string
  const newQueryString = Object.keys(currentParams)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(currentParams[k] || '')}`)
    .join('&');

  // Reconstruct URL with new query string and hash
  return newQueryString
    ? `${baseUrl}?${newQueryString}${hash ? `#${hash}` : ''}`
    : `${baseUrl}${hash ? `#${hash}` : ''}`;
}

export function updateSearchParams(queryParams: Record<string, string | null>): void {
  const newUrl = updateUrlQueryString(queryParams);
  window.history.replaceState({}, '', newUrl);
}

// export function getUrlSearchParams(url?: string): Record<string, string> {
//   const search = url ? url.match(/\?.*$/)?.[0] || '' : window?.location.search;
//   const searchObj = {};
//   if (search) {
//     const searchFields = search.replace(/^\?/, '').split('&');
//     for (let index = 0; index < searchFields.length; index++) {
//       const field = searchFields[index];
//       if (field.indexOf('=') > -1) {
//         const item = field.split('=');
//         searchObj[item[0]] = item[1];
//       }
//     }
//   }
//   return searchObj;
// }

export function getUrlSearchParams(url?: string): Record<string, string> {
  const search = url ? new URL(url).search : window?.location.search;
  const searchParams = new URLSearchParams(search);
  const searchObj: Record<string, string> = {};

  searchParams.forEach((value, key) => {
    searchObj[key] = value;
  });

  return searchObj;
}

export function parseUrl(url: string) {
  let origin = '';
  let pathname = '';

  const urlPattern = /^(https?:\/\/(?:localhost|[^/]+))?(\/.*)?$/;
  const match = url.match(urlPattern);

  if (match) {
    origin = match[1] || '';
    pathname = match[2] || '/';
  }

  return { origin, pathname };
}
