import classNames from 'classnames';
import Image from 'next/image';
import styles from './HeaderMobile.module.scss';

export default function HeaderMobile() {
  return (
    <div className={styles.header}>
      <Image src="/images/user/login_logo@2x.png" width="118" height="30" alt="" />
      <span className={styles.title}>智能文字识别产品</span>
    </div>
  );
}
