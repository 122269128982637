import cx from 'classnames';
import Image from 'next/image';
import { Carousel } from 'antd';
import MouseScroll from './components/NumberInfo';
import { BannerDataSource } from './dataSource';
import CheckBg from './assets/banner_check.svg';
import styles from './styles/banner.module.less';
import BannerTitle from '/public/images/home/pic_banner_slogan@2x.png';
import TextinTitle from '/public/images/home/home-white_textin.png';
import ArrowRightOutlined from '/public/images/home/ArrowRightOutlined@2x.png';
import { useInViewport } from 'ahooks';
import { useRef } from 'react';
import useWinTop from '@/utils/hooks/useWinTop';
import Link from '@/components/Link';
import { track } from '@/utils/track';
import { getLocalUserInfo, getToken } from '@/utils';
import { toDocFlow } from '../Product/dataSource/docflow';

const Banner1 = () => (
  <div className={styles['home-banner-container']}>
    <div className={styles['home-banner-bg']}>
      <Image
        src={'/images/home/banner@2x.png'}
        alt={BannerDataSource.content.title}
        width={1200}
        height={600}
        layout="fixed"
      />
    </div>
    <div className={styles.videoWrapper}>
      <video autoPlay muted loop playsInline>
        <source src="//static.textin.com/videos/web/background.mp4" type="video/mp4" />
      </video>
    </div>
    <div className={cx('home-page', styles['home-banner-content'])}>
      <div className={styles['title-wrapper']}>
        <div className={styles['title-warpper-image']}>
          <Image src={BannerTitle} height={26} width={164} alt="" priority={true} />
          <div className={styles['title-warpper-textin-image']}>
            <Image src={TextinTitle} height={40} width={163} alt="" priority={true} />
          </div>
          <p className={styles['title-warpper-text']}>智能文字识别产品</p>
          <p className={styles['title-warpper-content']}>
            为全球用户提供智能图像处理、文字表格识别、文档内容提取产品
          </p>
          <Link
            href={'/market/list'}
            onClick={() => track({ name: '按钮点击', keyword: '首页banner1【查看产品】' })}
          >
            <div className={styles['title-warpper-button']}>
              <span className={styles['title-warpper-button-text']}>查看产品</span>
              <Image src={ArrowRightOutlined} height={20} width={20} alt="" />
            </div>
          </Link>
        </div>

        {BannerDataSource.content.children.map((desc, i) => {
          return (
            <div key={i.toString()} className={styles['desc-wrapper']}>
              <CheckBg />
              <div className={styles.desc}>{desc}</div>
            </div>
          );
        })}
      </div>
    </div>
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Banner2 = () => {
  const isLogin = getLocalUserInfo() && getToken();
  return (
    <div className={cx(styles['home-banner-container'], styles['banner-2'])}>
      <div className={cx('home-page', styles['home-banner-content'])}>
        <div className={styles['title-wrapper']}>
          <div className={styles['title-warpper-image']}>
            <div className={styles['title-warpper-textin-image']}>
              <Image src={TextinTitle} height={40} width={163} alt="" />
            </div>
            <p className={styles['title-warpper-desc']}>
              <p>通用文档解析</p>
              <p>
                <span>100页</span>PDF仅需<span>2s</span>
              </p>
              <p>文字、表格、公式等一键智能识别</p>
            </p>
            <div className={styles['banner-btn']}>
              <Link
                href={
                  isLogin
                    ? '/console/recognition/robot_markdown?service=pdf_to_markdown'
                    : '/experience/pdf_to_markdown'
                }
                onClick={() =>
                  track({ name: '按钮点击', keyword: '首页banner通用文档解析【立即体验】' })
                }
              >
                <div className={styles['title-warpper-button']}>
                  <span className={styles['title-warpper-button-text']}>免费体验</span>
                  <Image src={ArrowRightOutlined} height={20} width={20} alt="" />
                </div>
              </Link>
            </div>
          </div>

          <div className={styles['banner-img']}>
            <img src={`/images/market/pdf2markdown/support_elements.png`} width={410} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

const Banner3 = () => {
  return (
    <div className={cx(styles['home-banner-container'], styles['banner-2'])}>
      <div className={cx('home-page', styles['home-banner-content'])}>
        <div className={styles['title-wrapper']}>
          <div className={styles['title-warpper-image']}>
            <div className={styles['title-warpper-textin-image']}>
              <Image src={TextinTitle} height={40} width={163} alt="" />
            </div>
            <p className={styles['title-warpper-desc']}>
              <p className={styles['title-warpper-text']}>DocFlow</p>
              <p className={styles['title-warpper-content']}>
                一站式智能文档数字化平台，为全球用户提供智能文档采集、分类及结构化信息抽取服务，有效提升企业业务处理效率
              </p>
            </p>
            <div className={styles['banner-btn']}>
              <Link
                onClick={() => {
                  track({ name: '按钮点击', keyword: '首页banner TextIn DocFlow【免费体验】' });
                  toDocFlow();
                }}
              >
                <div className={styles['title-warpper-button']}>
                  <span className={styles['title-warpper-button-text']}>免费体验</span>
                  <Image src={ArrowRightOutlined} height={20} width={20} alt="" />
                </div>
              </Link>
            </div>
          </div>

          <div className={styles['banner-img']} style={{ height: 270 }}>
            <img src={`/images/home/home-banner-docflow.png`} width={410} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

function Banner() {
  const bannerRef = useRef<HTMLDivElement | null>(null);
  const inViewPort = useInViewport(bannerRef);
  const top = useWinTop(60);
  const isScroll = top > 60;

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    // speed: 300,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
  };

  return (
    <div
      className={styles['home-banner']}
      ref={bannerRef}
      style={{ visibility: inViewPort ? 'visible' : 'hidden' }}
    >
      <Carousel {...settings} effect="fade" easing="ease-in-out">
        <Banner1 />
        <Banner2 />
        <Banner3 />
      </Carousel>

      <MouseScroll
        onClick={() => {
          bannerRef.current?.nextElementSibling?.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          });
        }}
      />

      <div
        className={cx(styles.bannerMask)}
        style={{ backgroundColor: isScroll ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0)' }}
      />
    </div>
  );
}
export default Banner;
