import type { CSSProperties } from 'react';
import { useEffect, useState } from 'react';
import { Popover } from 'antd';
import styles from './PortraitsEntry.module.scss';
import { userModelContainer } from '@/store/userModel';
import { isDemandsModalOpen, showDemandsModal } from '@/containers/User/Portraits/Demands';
import { isProfileModalOpen, showProfileModal } from '@/containers/User/Portraits/Profile';
import { getLocalUserInfo, getToken, isLogin } from '@/utils';
import dayjs from 'dayjs';
import { GlobalStoreContainer } from '@/store';
import { useRouter } from 'next/router';
import { CloseOutlined } from '@ant-design/icons';
import { isMobileClient } from '@/utils/browser';
import { showDemandsMobileModal } from '@/containers/User/Portraits/DemandsMobile';
import { track } from '@/utils/track';

export const lastTimeportraitsEntryPopCloseCacheKey = () =>
  `lastTimePortraitsEntryPopClose_${getLocalUserInfo()?.account || ''}`;

const PortraitsEntry = ({ style }: { style: CSSProperties }) => {
  const router = useRouter();
  const { userInfo, saveUserInfo } = userModelContainer.useContainer();
  const { isMobile } = GlobalStoreContainer.useContainer();
  const userPrefered = userInfo?.userPrefered;

  const [show, setShow] = useState(false);

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (getToken() && !userInfo?.account) {
      return;
    }
    if (!userPrefered?.demands || !userPrefered?.profile) {
      const lastTimeClosePopover = localStorage.getItem(lastTimeportraitsEntryPopCloseCacheKey());
      if (dayjs().diff(dayjs(lastTimeClosePopover), 'day') < 1) {
        return;
      }
      setTimeout(() => {
        if (!isDemandsModalOpen && !isProfileModalOpen) {
          setVisible(true);
        }
      });
    }
  }, [userPrefered, userInfo]);

  const closePopover = (e: any) => {
    e.stopPropagation();
    setVisible(false);
    localStorage.setItem(lastTimeportraitsEntryPopCloseCacheKey(), dayjs().toJSON());
  };

  const handleClick = () => {
    track({
      keyword: '右侧悬浮窗【加赠额度】',
      is_login: isLogin() ? '是' : '否',
      url: window.location.href,
    });

    if (!isLogin()) {
      isMobile
        ? window.open(`/user/login${`?redirect=${encodeURIComponent(location.pathname)}`}`, '_self')
        : router.push({
            pathname: `/user/login`,
            query: `redirect=${encodeURIComponent(location.pathname)}`,
          });
      return;
    }
    if (isMobileClient()) {
      if (!userPrefered?.demands && !userPrefered?.profile) {
        showDemandsMobileModal({
          force: true,
        });
        return;
      }
    }
    if (!userPrefered?.demands) {
      showDemandsModal({
        force: true,
        onSubmit(data) {
          saveUserInfo(data);
        },
      });
      return;
    }
    if (!userPrefered?.profile) {
      showProfileModal({
        force: true,
        onSubmit(data) {
          saveUserInfo(data);
        },
      });
    }
  };

  const shouldHidden =
    (isLogin() && !userInfo?.account) ||
    userInfo?.distributorId ||
    userInfo?.customerServiceId ||
    (userPrefered?.demands && userPrefered?.profile);

  useEffect(() => {
    setTimeout(() => {
      setShow(!shouldHidden);
    });
  }, [shouldHidden]);

  if (!show) {
    return null;
  }

  return (
    <Popover
      key={'portrait-entry'}
      placement="leftTop"
      content={
        <div className={styles.popoverContainer} onClick={handleClick}>
          <div className={styles.title}>诚邀您完善信息</div>
          <div className={styles.titleStrong}>领取1000次/页额度</div>
          <CloseOutlined onClick={closePopover} className={styles.closeIcon} />
        </div>
      }
      overlayClassName={styles.entryPopover}
      visible={visible}
      // getPopupContainer={(node) => node.parentElement?.parentElement}
      getPopupContainer={() => document.querySelector('#consult-slide') || document.body}
      trigger={['hover']}
      onVisibleChange={(visible) => setVisible(visible)}
    >
      <div className={styles.entry} style={style} onClick={handleClick}>
        加赠额度
      </div>
    </Popover>
  );
};

export default PortraitsEntry;
