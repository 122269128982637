export const track = function (params: {
  [key: string]: any;
  name?: string;
  keyword?: string;
  userId?: string;
}) {
  try {
    const { name, keyword, userId, ...rest } = params;
    // console.log(params);
    if (window.zhuge) {
      if (name === '按钮点击') {
        window.zhuge.track(keyword, { user_id: userId, ...rest });
      } else if (name === '内容曝光') {
        window.zhuge.track(keyword + ` (曝光)`, { user_id: userId, ...rest });
      } else if (name) {
        window.zhuge.track(name, { keyword, user_id: userId, ...rest });
      } else {
        window.zhuge.track(keyword, { user_id: userId, ...rest });
      }
    } else {
      delayTrack(params);
    }
  } catch (error) {
    console.error(error);
  }
};

const delayTrack = (params: any, num: number = 1) => {
  if (num > 5) return;
  if (window.zhuge) {
    track(params);
  } else {
    const delay = 500;
    setTimeout(() => {
      delayTrack(params, num + 1);
    }, delay);
  }
};
