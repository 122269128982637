import type { IUserInfo } from './Index';

export const transformUserInfoFromApi = (res: IUserInfo) => {
  try {
    const usePrefered = res.data?.user_prefered;
    const clueIds = res.data?.clue_ids;
    return {
      ...res,
      data: {
        ...res.data,
        user_prefered: usePrefered ? JSON.parse(usePrefered) : usePrefered,
        clue_ids: clueIds ? clueIds.split(',') : clueIds,
      },
    };
  } catch (error) {
    return res;
  }
};
