import { toContract } from '@/containers/Product/dataSource';
import { toDocFlow } from '@/containers/Product/dataSource/docflow';
import { toIntFinQ } from '@/containers/Product/dataSource/knowledge_base';

export const hotServices: string[] = [
  'pdf_to_markdown',
  'manipulation_detection',
  'watermark-remove',
  'id_card',
  'vehicle_license',
  'business_card',
  'bill_recognize_v2',
];
export const newServices: string[] = [
  'open_kie_vlm_engine',
  'face_forgery_detection',
  'open_kie_credentials',
];
export const priorityDisplayServices = [
  /// 大模型加速器
  'pdf_to_markdown', // 通用文档解析
  'open_kie_vlm_engine', // 智能文档抽取
  /// 通用识别
  'recognize-document-3d1-multipage', // 通用文字识别
  'recognize_table_multipage', // 通用表格识别
  'handwritten_signature_recognize', // 手写签名
  'recognize_stamp', // 印章检测
  'qr_code', // 二维码
  /// AI篡改检测
  'manipulation_detection', // 通用篡改检测
  'face_forgery_detection', // 人脸伪造检测
  /// 图像智能处理
  'watermark-remove', // 图像水印去除
  'crop_enhance_image', // 文档图像切边增强矫正
  'text_auto_removal', // 自动擦除手写文字
  'image_quality_inspect', // 图像质量检测
  /// 卡证识别
  'id_card', // 身份证
  'business_license', //营业执照
  'vehicle_license', //行驶证
  'driver_license', // 驾驶证
  'open_kie_credentials', // 企业资质证照
  'business_card', // 名片
  'bank_card', // 银行卡
  'passport', // 护照
  'vehicle_registration_certificate', // 车辆登记证
  'vehicle_inspection_certificate', // 车辆合格证
  'cert_classify', // 证件分类
  'hk_mac_mainland_travel_permit', // 港澳居民来往内地通行证
  /// 票据识别
  'bill_recognize_v2', // 国内通用票据
  'verify_vat', // 发票验真
  'general_receipt_classify', // 票据分类
  'bank_receipts', // 银行回单
  'tax_payment_certificate', // 完税证明
  'electr_acceptance_bill', // 电子承兑汇票
  'receipt', // 商铺小票
  'customs_declaration', // 海关进出口货物报关单
  'commercial_insurance_policy', // 汽车商业险保单
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hiddenServices: string[] = [
  'doc_extraction', // 合同抽取
  'doc_compare', // 合同比对
  'document', // 办公文档识别
  'contents-extract', // 通用NLP信息抽取
  'recognize-document-3d1-multipage', // 通用文字识别多页PDF版
  'verify_vat', // 发票验真
  'blockchain_validate', // 区块链发票验真
];

export const experienceScheneProducts = [
  {
    name: 'DocFlow票据自动化',
    app_name: 'DocFlow票据自动化',
    description:
      '票据AI自动化处理，支持不同版式单据智能分类扩展，可选功能插件配置流程，满足多样业务场景。',
    id: -4,
    linkTo: toDocFlow,
  },
  {
    name: '合同比对',
    app_name: '合同比对',
    description:
      '依托于合合信息强大的文档解析、元素比对技术，提供多格式文档一键比对、差异精准定位、差异结果一键导出等功能。能精准找出不同文档文本段落、表格、印章之间的差异，分钟级别完成百页文档比对。显著降低企业在合同管理全流程中的人工审核成本，减少因合同差异带来的潜在风险。',
    search_keywords: '合同比对',
    id: -1,
    linkTo: () => toContract('compare'),
  },
  {
    name: '合同抽取',
    app_name: '合同抽取',
    description:
      'TextIn合同抽取为您提供合同文档智能化信息抽取服务。该服务基于合合信息强大的文字识别能力，结合了文档解析、文档检索和文本生成三项核心技术。提供多类型合同文档的关键要素提取和比对的能力。',
    search_keywords: '合同抽取',
    id: -2,
    linkTo: () => toContract('extraction'),
  },
  {
    name: '知识管理及写作助手',
    app_name: '知识管理及写作助手',
    description:
      '支持多种文件格式，轻松打造个人知识库，通过知识检索、智能辅助创作，有效提升知识写作质量及效率，成为您的高效知识写作助手。',
    id: -3,
    linkTo: toIntFinQ,
  },
];

export const isOpenKie = (item: any) => {
  return [17].includes(item.interaction) || ['open_kie_vlm_engine'].includes(item.service);
};

// 直接跳转至工作台使用
export const linkToConsole = (item: any) => {
  return ['verify_vat'].includes(item.service);
};

export const filetHiddenList = (list: any[]) => {
  if (!Array.isArray(list)) return [];
  // 隐藏无体验中心的服务，智能文档抽取例外
  return list.filter(
    (item) =>
      (item.exper_center_status === 1 || isOpenKie(item) || linkToConsole(item)) &&
      item.interaction !== -1,
  );
};
