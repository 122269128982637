import type { FC } from 'react';
import { useState } from 'react';
import type { SelectProps } from 'antd';
import { Select, Spin, message, Image } from 'antd';
import { useDebounceFn } from 'ahooks';
import classNames from 'classnames';
import type { ICompanyListItem } from '@/service/common/Index';
import { queryCompanyFromQixin } from '@/service/common/Index';
import styles from './index.module.less';

const CompanySelect: FC<
  SelectProps<any> & { onSelected?: (value: any) => void } & { filterStatus?: boolean } & {
    width?: number;
  }
> = ({ onSelected, filterStatus, width, className, dropdownClassName, ...props }) => {
  const { OptGroup } = Select;
  const [loading, setLoading] = useState(false);
  const [companyList, setCompanyList] = useState<ICompanyListItem[]>([]);
  const [status, setStatus] = useState<boolean>(false);

  const { run } = useDebounceFn(
    async (value) => {
      try {
        setLoading(true);
        const { data } = await queryCompanyFromQixin(value);
        setStatus(() => {
          if (data.list.length <= 0) {
            return true;
          } else {
            return false;
          }
        });
        if (filterStatus) {
          setCompanyList(data.list.filter((item) => item.credit_no !== '-'));
        } else {
          setCompanyList(data.list);
        }
      } catch (e: any) {
        message.warn(e.message);
      } finally {
        setLoading(false);
      }
    },
    { wait: 500 },
  );

  return (
    <Select
      {...props}
      className={classNames(styles['company-select'], className)}
      dropdownClassName={classNames(styles['company-select-dropdown'], dropdownClassName)}
      showSearch={true}
      notFoundContent={loading ? <Spin size="small" /> : null}
      filterOption={false}
      showArrow={false}
      onSearch={run}
      onSelect={(value, option) => {
        const selectedItem = companyList.find((item) => item.id === option.key);
        onSelected?.(selectedItem);
      }}
      style={{ width: width }}
      // onFocus={run}
      onClear={() => {
        props.onClear?.();
        run('""');
      }}
      allowClear
      clearIcon={
        <Image
          src="/icons/select_clear_icon.png"
          width={16}
          height={16}
          preview={false}
          alt="icon"
        />
      }
      menuItemSelectedIcon={
        <Image src="/icons/tick@2x.png" width={20} height={20} preview={false} alt="icon" />
      }
    >
      {(status || !!companyList.length) && <OptGroup label="信息检索服务由启信宝提供" />}
      {companyList.map((list) => (
        <Select.Option key={list.id} value={list.name} disabled={list.id ? false : true}>
          {list.name}
        </Select.Option>
      ))}
      {status && <OptGroup label="无结果" />}
    </Select>
  );
};
export default CompanySelect;
