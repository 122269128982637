import { useEffect, useMemo, useRef, useState } from 'react';
import { Popover } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { track } from '@/utils/track';
import dayjs from 'dayjs';
import wechat from './assets/wechat.png';
import mannual from './assets/icon_mannual.png';
import WechatPopImage from './assets/wechat-pop-card.png';
import styles from './index.module.less';
import { getQrCodeByUrlPath } from '@/constants/qrcode';

const WechatCode = ({ type = 'manual' }: { type?: 'manual' | 'tech' }) => {
  const router = useRouter();
  const currentPath = router.asPath?.split('?')[0];

  const popoverRef = useRef<HTMLDivElement>(null);

  const info = useMemo(() => {
    let defaultInfo = {
      slide: '人工咨询',
      slideClass: '',
      title: '1vs1 人工VIP咨询',
      code: getQrCodeByUrlPath(currentPath),
      icon: mannual,
      desc: (
        <div>
          <div>扫码添加</div>
          <div>获取解决方案/大额折扣</div>
        </div>
      ),
      tags: [
        { icon: '/images/qrcode/ic-card-Welfare@2x.png', text: '大额折扣' },
        { icon: '/images/qrcode/ic-card-Discount@2x.png', text: '产品咨询' },
        { icon: '/images/qrcode/ic-card-comminicate@2x.png', text: '技术答疑' },
        { icon: '/images/qrcode/ic-card-communicate@2x.png', text: '生态合作' },
      ],
      tips: (
        <>
          <div>想要获得技术咨询，测试折扣？</div>
          <div>扫码添加，免费制定解决方案！</div>
        </>
      ),
      defaultVisible: false,
    };
    if (type === 'tech') {
      defaultInfo = {
        ...defaultInfo,
        slide: '技术交流群',
        title: '加入社群，实时技术交流',
        icon: wechat,
        desc: (
          <div>
            <div>扫码加入</div>
            <div> 6000+技术同行交流群</div>
          </div>
        ),
        tips: (
          <>
            <div>想要获得技术咨询，测试折扣？</div>
            <div>加入社群，答疑解惑领取福利！ </div>
          </>
        ),
      };
    }
    return defaultInfo;
  }, [currentPath]);

  const [visible, setVisible] = useState(info.defaultVisible);

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
    if (visible) {
      track({
        name: '内容曝光',
        keyword: `侧边栏【官方社群】`,
        button_name: info.slide,
        url: window.location.href,
      });
    }
  };

  const apiDocTipsTimer = useRef<any>(null);

  const removeApiDocTipsTimer = () => {
    if (apiDocTipsTimer.current) {
      clearTimeout(apiDocTipsTimer.current);
    }
  };
  const addApiDocTipsTimer = () => {
    apiDocTipsTimer.current = setTimeout(() => {
      const lastTipsTime = localStorage.getItem('lastApiDocTipsTime');
      if (lastTipsTime && dayjs().diff(lastTipsTime, 'day') < 1) {
        return;
      }
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 15000);
      localStorage.setItem('lastApiDocTipsTime', dayjs().toJSON());
      track({
        name: '内容曝光',
        keyword: '社群交流_主动弹出',
      });
    }, 30000);
  };
  useEffect(() => {
    const showApiDocTipsRoutes = ['/document/pdf_to_markdown'];
    if (showApiDocTipsRoutes.includes(currentPath)) {
      removeApiDocTipsTimer();
      addApiDocTipsTimer();
    } else {
      removeApiDocTipsTimer();
    }
  }, [currentPath]);

  const wechatCodePopover = (
    <div className={styles.wechatCode}>
      <CloseOutlined
        className={styles.close}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          popoverRef.current?.click();
        }}
      />

      <div className={styles.wechatCodeTitle}>{info.title}</div>
      <div className={styles.wechatCodeImgWrapper}>
        <Image
          style={{ boxShadow: '0px 0px 20px 0px rgba(19, 18, 60, 0.14)' }}
          src={info.code}
          width={122}
          height={122}
          alt=""
        />
      </div>
      <div className={styles.wechatCodeDesc}>{info.desc}</div>

      {Array.isArray(info.tags) && (
        <div className={styles.wechatCodeTags}>
          {info.tags.map((tag, rowIdx) => (
            <div className={styles.wechatCodeTag} key={rowIdx}>
              <Image src={tag.icon} width={20} height={20} alt="" />
              <span style={{ marginLeft: 6 }}>{tag.text}</span>
            </div>
          ))}
        </div>
      )}
      <div className={styles.apiDocTipsPopover}>
        <Image src={WechatPopImage} alt="" />
        <div className={styles.apiDocTipsText}>{info.tips}</div>
      </div>
    </div>
  );

  return (
    <Popover
      placement="leftBottom"
      content={wechatCodePopover}
      overlayClassName={styles.wechatCodePopover}
      defaultVisible={true || info.defaultVisible}
      visible={visible}
      key={info.title}
      getPopupContainer={() => document.querySelector('#consult-slide') || document.body}
      trigger={['hover', 'click']}
      onVisibleChange={handleVisibleChange}
    >
      <div className={styles.wechat} ref={popoverRef}>
        <div className={styles.icon}>
          <Image src={info.icon} width={24} height={24} alt="" />
        </div>
        <div className={styles.textWrapper}>
          <span className={info.slide.length > 4 ? styles.small : undefined}>{info.slide}</span>
        </div>
      </div>
    </Popover>
  );
};

export const WechatCodeSlide = (props) => (
  <div className={styles['wechat-slide']}>
    <WechatCode {...props} />
  </div>
);

export default WechatCode;
