import * as Sentry from '@sentry/nextjs';

export const Logger = (title: string, ...rest: any[]) => {
  console.log(`[${new Date().toLocaleString()}]`, title, ...rest);
  sentryLog.log(title, ...rest);
};

class CustomError extends Error {
  constructor(name: string, ...rest: any[]) {
    super();
    this.name = name;
    this.message = Array.isArray(rest)
      ? rest.map((msg) => this.toString(msg)).join('\n')
      : this.toString(rest);
  }

  toString(msg: any) {
    if (msg instanceof Error) {
      return msg.toString();
    }
    return JSON.stringify(msg);
  }
}

export const sentryLog = {
  log: (title: string, ...rest: any[]) => {
    try {
      Sentry.captureException(new CustomError(title, ...rest), { level: 'log' });
    } catch (error) {
      console.log('sentryLog.log error', error);
    }
  },
  error: (title: string, ...rest: any[]) => {
    try {
      Sentry.captureException(new CustomError(title, ...rest), { level: 'error' });
    } catch (error) {
      console.log('sentryLog.error error', error);
    }
  },
};
