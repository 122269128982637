import styles from './index.module.less';

// export const NewIcon = () => {
//   return <span className={styles['icon-item']}>NEW</span>;
// };

// export const HotIcon = () => {
//   return <span className={styles['icon-item']}>HOT</span>;
// };

export const NewTag = () => {
  return <span className={styles.tag}>NEW</span>;
};

export const HotTag = () => {
  return <span className={styles.tag}>HOT</span>;
};

export const NewIcon = NewTag;

export const HotIcon = HotTag;
