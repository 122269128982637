import type { FC } from 'react';
import { Button, Modal, Row } from 'antd';
import dayjs from 'dayjs';
import Image from 'next/image';
import WechatCode from './assets/wechatCode.png';
import styles from './Wechat.module.scss';
import { getLocalUserInfo } from '@/utils';
import { track } from '@/utils/track';

const Wechat: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>欢迎来到 TextIn 智能文档平台</div>
      <div className={styles.desc}>添加TextIn文本智能福利官，领取超多福利</div>
      <div className={styles.content}>
        <div className={styles.qrcodeArea}>
          <Image src={WechatCode} width={150} height={150} alt="" />
          <div className={styles.qrcodeText}>添加Textin福利官</div>
        </div>
        <div className={styles.giftArea}>
          <Row className={styles.giftItem} align="middle">
            <div className={styles.giftLabel}>首次添加享</div>
            <div className={styles.giftContent}>
              <span>加赠免费额度</span>
              <span className={styles.giftTextStrong}>1000次/页</span>
            </div>
          </Row>
          <Row className={styles.giftItem} align="middle">
            <div className={styles.giftLabel}>首次充值享</div>
            <div className={styles.giftContent}>
              <span>首次充值 (100元以上)</span>
              <span className={styles.giftTextStrong}>送100页</span>
            </div>
          </Row>
          <Row className={styles.giftItem} align="middle">
            <div className={styles.giftLabel}>入群领券包</div>
            <div className={styles.giftContent}>
              <span>每周可领超多品类</span>
              <span className={styles.giftTextStrong}>8折券</span>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
};

const showLeadsWechatModalCountCacheKey = () =>
  `showLeadsWechatModalCount_${getLocalUserInfo()?.account || ''}`;
const lastTimeShowLeadsWechatModalCacheKey = () =>
  `lastTimeShowLeadsWechatModal_${getLocalUserInfo()?.account || ''}`;
export const showWechatModal = ({ onClose }: { onClose?: () => void } = {}) => {
  const hasShownCount = localStorage.getItem(showLeadsWechatModalCountCacheKey()) || 0;
  const lastShowModalTime = localStorage.getItem(lastTimeShowLeadsWechatModalCacheKey());
  if (
    (hasShownCount && Number(hasShownCount) >= 3) ||
    dayjs().diff(dayjs(lastShowModalTime), 'day') < 1
  ) {
    onClose?.();
    return;
  }
  Modal.info({
    className: styles.modal,
    maskStyle: {
      backdropFilter: 'blur(5px)',
      WebkitBackdropFilter: 'blur(5px)',
      backgroundColor: '#141f334d',
    },
    centered: true,
    icon: null,
    content: <Wechat />,
    width: 620,
    closable: true,
    onCancel: onClose,
  });
  localStorage.setItem(lastTimeShowLeadsWechatModalCacheKey(), dayjs().toJSON());
  localStorage.setItem(
    showLeadsWechatModalCountCacheKey(),
    (Number(hasShownCount || 0) + 1).toString(),
  );
  track({
    keyword: '线索留存【二维码弹窗】（曝光）',
    url: window.location.href,
    trigger: '登录流程',
    device: 'pc',
  });
};

export default Wechat;
