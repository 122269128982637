import { message } from 'antd';
import type { CSSProperties } from 'react';
import { copy } from '@/utils/copy';

export default function TelePhone({
  className,
  style,
  phone,
  shouldCopy = true,
}: {
  className?: string;
  style?: CSSProperties;
  phone: string;
  shouldCopy?: boolean;
}) {
  return (
    <a
      href={`tel:${phone}`}
      className={className}
      style={style}
      onClick={() => {
        if (shouldCopy) {
          copy(phone);
          message.config({ top: 400 });
          message.success('已复制号码');
        }
      }}
    >
      {phone}
    </a>
  );
}

export const callPhone = (
  phone: string,
  {
    shouldCopy = true,
  }: {
    shouldCopy?: boolean;
  } = {},
) => {
  const a = document.createElement('a');
  a.href = `tel:${phone}`;
  a.addEventListener('click', () => {
    if (shouldCopy) {
      copy(phone);
      message.config({ top: 400 });
      message.success('已复制号码');
    }
  });
  a.click();
};
